.rating-component__button {
    width: 27px;
    height: 27px;
    margin: 0 2px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: normal;
    border: none;
    color: #fff;
    background: #F4F4F4;
    transform: scale(1) !important;
    transition: 0.4s ease;
}
.rating-component__button.detractor-hover {
    background: #F44336;
    transform: scale(1.05);
}
.rating-component__button.passive-hover {
    background: #F57C00;
    transform: scale(1.05);
}
.rating-component__button.promoter-hover {
    background: #4CAF50;
    transform: scale(1.05);
}
